import React from "react";
import "./App.css";
import { HuePicker, AlphaPicker } from "react-color";

const PickerComponent = (props) => {

  const { visible } = props;
  const { color, setColor } = props;

  const [light, setLight] = React.useState({ ...color, a: color.l });

  const onChangeColor = (nextColor) => {
    setColor({ h: nextColor.hsl.h, s: nextColor.hsl.s, l: light.a, a: nextColor.hsl.a })
  }

  const onChangeLight = (nextLight) => {
    setColor({ h: color.h, s: color.s, l: nextLight.hsl.a, a: color.a })
    setLight(nextLight.hsl)
  }

  const makePicker = (Type, valueObject, onChangeFunc) => () => <Type
    color={valueObject}
    onChangeComplete={onChangeFunc}
    style={{ width: '100%' }}
  />

  const HuePickerComponent = makePicker(HuePicker, color, onChangeColor)
  const LightPickerComponent = makePicker(AlphaPicker, light, onChangeLight)

  return (
    <div className={'picker-wrapper'} style={{visibility: visible ? 'visible' : 'hidden'}}>
      <HuePickerComponent />
      <LightPickerComponent />
    </div>

  );
};

const App = () => {
  const [color, setColor] = React.useState({ h: 180, s: 1, l: 0.5, a: 1 })
  const [showColorPicker, setShowColorPicker] = React.useState(false)

  return (
    <>
      <PickerComponent color={color} setColor={setColor} visible={showColorPicker} />
      <div
        className="App"
        style={{ backgroundColor: `hsla(${color.h}, ${color.s * 100}%, ${color.l * 100}%, ${color.a})` }}
        onClick={() => setShowColorPicker(!showColorPicker)}

      >
      </div>
    </>

  );
};

export default App;
